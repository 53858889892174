<template>
  <div class="sidebar-filters">
    <b-button class="text-uppercase" ref="escape" variant="reset-all" @click.prevent="resetFilters()">
      <i class="fas fa-eraser" /> {{ 'Reset all filters' | translate }}
    </b-button>
    <invoice-status-list-filter
      @edit="editComponentFilters($event, 'filter__status__in')"
      @clean="cleanComponentFilters(['filter__status__in'])"
    />
    <invoice-typology-filter
      @edit="editComponentFilters($event, 'filter__typology__in')"
      @clean="cleanComponentFilters(['filter__typology__in'])"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as INVOICE_TYPES } from './store'
import CommonFiltersMixin from '../../common-filters/CommonFiltersMixin'

export default {
  name: 'InvoiceCommonFilters',
  mixins: [CommonFiltersMixin],
  computed: {
    ...mapGetters({
      filters: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...INVOICE_TYPES.GENERIC.invoice.invoice.LIST.ACTIONS
    })
  }
}
</script>
