<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as INVOICE_TYPES } from './store'
import { ROUTES as INVOICE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import InvoiceTable from './InvoiceTable'
import InvoiceCommonFilters from './InvoiceCommonFilters'
// import InvoiceEdit from './InvoiceEdit.vue'

export default {
  name: 'InvoiceList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': InvoiceTable,
    'common-filters': InvoiceCommonFilters
    // 'component-edit': InvoiceEdit,
    // 'component-detail': InvoiceEdit
  },
  data () {
    return {
      INVOICE_ROUTES,
      title: this.$t('Invoices'),
      rolePerm: ['invoice_invoice_list'],
      actionEnablePermission: ['invoice_invoice_enable'],
      actionDisablePermission: ['invoice_invoice_disable'],
      searchFields: [
        { key: 'filter__recipient__name__icontains', placeholder: this.$t('Recipient'), type: 'text', col: 6 },
        { key: 'filter__date__year', placeholder: this.$t('Year'), type: 'text', col: 6 },
        { key: 'filter__date__gte', placeholder: this.$t('Date greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date__lte', placeholder: this.$t('Date less than equal'), minDate: 'filter__date_q__gte', type: 'date', col: 6 }
      ],
      actions: [
        { value: null, text: '---' },
        {
          value: 'setAsSent',
          text: this.$t('Set as sent to SDI'),
          permissions: 'invoice_invoice_set_issued'
        },
        {
          value: 'setAsNotSent',
          text: this.$t('Set as not sent to SDI'),
          permissions: 'invoice_invoice_set_inserted'
        }
      ].filter(element => {
        return hasPerm(element.permissions)
      })
    }
  },
  computed: {
    ...mapGetters({
      ...INVOICE_TYPES.GENERIC.invoice.invoice.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.INVOICE_ROUTES.INVOICE_INVOICE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.MUTATIONS.setSelectedList,
      setSelectAll: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...INVOICE_TYPES.GENERIC.invoice.invoice.LIST.ACTIONS
    }),
    openDetailForm (id) {
      this.$router.push({ name: INVOICE_ROUTES.INVOICE_INVOICE_DETAIL, params: { id: id } })
    },
    openSelectXLSRecipient () {

    },
    doCustomActions (actionType, idList, counter) {
      if (actionType === 'setAsSent') {
        idList.forEach((id) => {
          this.setIssued(id).then(() => {
            if (counter === idList.length - 1) {
              this.getList()
              this.setSelectedList([])
            }
            counter += 1
          })
        })
      }
      if (actionType === 'setAsNotSent') {
        idList.forEach((id) => {
          this.setInserted(id).then(() => {
            if (counter === idList.length - 1) {
              this.getList()
              this.setSelectedList([])
            }
            counter += 1
          })
        })
      }
    }
  }
}
</script>
