<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      :show-status-slot="false"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="status_name" slot-scope="row">
        <span>
          {{row.item.get_status_display}}
        </span>
      </template>
      <template slot="total_income" slot-scope="row">
        <span>
          {{row.item.total_income}} €
        </span>
      </template>
      <template slot="total_vat" slot-scope="row">
        <span>
          {{row.item.total_vat}} €
        </span>
      </template>
      <template slot="total_amount" slot-scope="row">
        <span>
          {{row.item.total_amount}} €
        </span>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
          <b-btn
            variant="outline-primary"
            v-has-perms="SendEmailRolePerm"
            :title="'Send invoice by email' | translate"
            :disabled="row.item.is_closed"
            @click.prevent="showModal(row.item, 'showSendEmailModal')"
          >
            <i class="fas fa-envelope"></i>
          </b-btn>

        <b-btn
          variant="outline-primary"
          v-has-perms="downloadPDFRolePerm"
          :disabled="row.item.order_done"
          :title="'Download PDF' | translate"
          @click.prevent="downloadPDF(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn>

        <b-btn
          variant="outline-primary"
          v-has-perms="downloadXMLRolePerm"
          :disabled="row.item.order_done"
          :title="'Download XML' | translate"
          @click.prevent="downloadXML(row.item)"
        >
          <i class="fas fa-file-invoice"></i>
        </b-btn>
      </template>
    </thux-table>
    <send-email-modal
      v-if="showSendEmailModal && invoice && invoice.id"
      :show-owner-emails='true'
      :title="$t('Send invoice by email')"
      @submit="sendInvoiceEmail"
      :default-email="invoice.recipient_default_email"
      @close="closeModal"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as INVOICE_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import JobMixin from '@/components/job-button/JobMixin'
import SendEmailModal from '@/components/SendEmailModal'
import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'InvoiceTable',
  components: { SendEmailModal },
  mixins: [ThuxTableMixin, ThuxTableChecksMixin, JobMixin],
  data () {
    return {
      showSendEmailModal: false,
      invoice: null,
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status_name',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'typology',
          label: this.$t('Typology'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'get_typology_display'
        },
        {
          key: 'number',
          label: this.$t('Number'),
          type: 'Number',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'number'
        },
        {
          key: 'date',
          label: this.$t('Date'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'date'
        },
        {
          key: 'recipient',
          label: this.$t('Recipient'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'recipient_name'
        },
        {
          key: 'total_income',
          label: this.$t('Total income'),
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          custom: true
        },
        {
          key: 'total_vat',
          label: this.$t('Total VAT'),
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          custom: true
        },
        {
          key: 'total_amount',
          label: this.$t('Total amount'),
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'name'],
      // Permissions
      SendEmailRolePerm: 'invoice_invoice_send_invoice',
      downloadPDFRolePerm: 'invoice_invoice_print_pdf',
      downloadXMLRolePerm: 'invoice_invoice_print_xml'

    }
  },
  computed: {
    ...mapGetters({
      apiUrl: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.GETTERS.apiUrl,
      selectAll: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.GETTERS.selectAll,
      selectedList: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('invoice_invoice_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.MUTATIONS.setList,
      setSelectedList:
        INVOICE_TYPES.GENERIC.invoice.invoice.LIST.MUTATIONS.setSelectedList,
      setSelectAll: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...INVOICE_TYPES.GENERIC.invoice.invoice.LIST.ACTIONS
    }),
    showModal (item, showModalName) {
      this.$set(this, 'invoice', item)
      this.$set(this, showModalName, true)
    },
    sendInvoiceEmail (emails) {
      this.$set(this, 'apiCallJobUrl', `${this.apiUrl}/${this.invoice.id}/send-invoice/`)
      this.doJob({ emails: emails })
    },
    closeModal () {
      this.$set(this, 'invoice', null)
      this.$set(this, 'showSendEmailModal', false)
    },
    downloadPDF (item) {
      const fileName = `${this.$t('Invoice')}_${item.number}_${item.date}.pdf`
      this.download({ id: item.id, action: 'print-pdf', simpleFileName: fileName })
    },
    downloadXML (item) {
      const dateObj = moment(new Date(item.date))
      const fileName = `${this.$t('Invoice')}_${item.number}_${dateObj.format('DD_MM_YYYY')}.xml`
      this.download({ id: item.id, action: 'print-xml', simpleFileName: fileName })
    },
    afterExecuteJob () {
      this.$set(this, 'showSendEmailModal', false)
      this.$set(this, 'invoice', undefined)
    }
  }
}
</script>
